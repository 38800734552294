.app {
  background: #161924;
  height: 100vh;
  overflow: scroll;
  color: var(--crow-white);
  text-align: center;
}

.app-container {
  /* padding for small screens*/
  padding: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}


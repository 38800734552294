.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

img {
    width: 140px;
}

.connect-button {
    width: 160px;
    height: 55px;
    font-size: var(--font-medium);
    color: var(--crow-red);
    border-radius: var(--border-radius-small);
    border: 1px solid var(--crow-red);
    background-color: rgb(0, 0, 0, 0);
    opacity: 0.8;
    transition: 0.2s;
}

.connect-button:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.2s;
}
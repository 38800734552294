.add-vault-container {
    border-radius: var(--border-radius-large);
    background-color: var(--background-dark);
    padding: 25px;
    width: 440px;
    box-shadow: var(--box-shadow);
}

@media screen and (max-width: 720px) {
    .add-vault-container {
        width: 100%;
    }
}

.form-field-container {
    height: 70px;
    border-radius: var(--border-radius-medium);
    background: var(--background-dark);
    padding: 10px 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    box-shadow: var(--box-shadow);
}

.form-field {
    font-size: var(--font-medium);
    background: rgba(255, 255, 255, 0);
    width: 100%;
    border: none;
    outline: none;
    padding: 0px 20px;
    color: white;
    height: 55px;
}

.form-field-square {
    min-width: 55px;
    min-height: 55px;
    border-radius: var(--border-radius-medium);
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-add-amount {
    background: var(--crow-orange-dark);
    color: var(--crow-orange);
}

.icon-add-address {
    background: var(--crow-pink-dark);
    color: var(--crow-pink);
}

.icon-add-time {
    background: var(--crow-purple-dark);
    color: var(--crow-purple);
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
}
.vault-list-container {
    background-color: var(--background-dark);
    padding: 30px;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius-large);
}

.vault-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: var(--crow-white);
}

h3 {
    text-align: left;
}
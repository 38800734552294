.cancel-button {
    width: 100%;
    height: 55px;
    font-size: var(--font-medium);
    color: var(--crow-red);
    border-radius: var(--border-radius-small);
    border: 1px solid var(--crow-red);
    background-color: rgb(0, 0, 0, 0);
    opacity: 0.8;
    transition: 0.2s;
}

.cancel-button:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.2s;
}
@import "../../styles.css";

.vault-container {
    border-radius: var(--border-radius-medium);
    background-color: var(--background-dark);
    padding: 10px 20px;
    box-shadow: var(--box-shadow);
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.vault-address {
    width: 180px;
    text-align: left;
}

.vault-unlock-date {
    text-align: left;
}

.square {
    height: 55px;
    min-width: 55px;
    border-radius: var(--border-radius-medium);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    transition: 0.2s;
}

.square:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.2s;
}

.icon-info {
    font-size: 24px;
    width: 40px;
    background: var(--crow-teal-dark);
    color: var(--crow-teal);
}

.icon-unlock {
    font-size: 20px;
    background: var(--crow-orange-dark);
    color: var(--crow-orange);
}

.icon-claim {
    font-size: 20px;
    background: var(--crow-green-dark);
    color: var(--crow-green);
}

.icon-eth-amount {
    width: 110px;
    text-align: right;
    color: white;
    font-size: var(--font-medium);
}

.icon-sender {
    font-size: 20px;
    width: 40px;
    background: var(--crow-teal-dark);
    color: var(--crow-teal);
}

.spacer {
    flex-grow: 2;
}


.add-button {
    width: 150px;
    height: 50px;
    font-size: var(--font-medium);
    border-radius: var(--border-radius-small);
    border: 1px solid var(--crow-red);
    background-color: rgb(0,0,0,0);
    color: var(--crow-red);
    transition: 0.2s;
    opacity: 0.8;
}

.add-button:hover {
    cursor: pointer;
    opacity: 1;
    transition: 0.2s;
}

.add-vault-button-text {
    margin-left: 10px;
}
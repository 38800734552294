:root {
    --crow-red: #DC2627;
    --crow-white: #F0EDEE;
    --crow-orange-dark: #423535;
    --crow-orange: #FA7315;
    --crow-pink-dark: #44303C;
    --crow-pink: #F43F5E;
    --crow-purple-dark: #392F49;
    --crow-purple: #9333EA;
    --crow-background-dark: #1F1E29;
    --crow-background: #292832;
    --crow-green-dark: #2F3D3C;
    --crow-green: #21C55E;
    --crow-teal-dark: #3A3B49;
    --crow-teal: #48B2FF;
    --crow-blue-dark: #3A3B49;
    --crow-blue: #3C405E;

    --border-radius-large: 25px;
    --border-radius-medium: 15px;
    --border-radius-small: 10px;

    --box-shadow: 0 3px 20px rgb(0 0 0 / 0.2);

    --font-medium: 16px;
    --font-large: 24px;
    --font-small: 12px;

    --background-dark: rgba(255, 255, 255, 0.03);
  }
